// import download from "../../images/svgs/download.svg"
// import facebook from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg"
// import Indent from "../indent"
// import PropTypes from "prop-types"
// import shareNode from "@fortawesome/fontawesome-free/svgs/solid/share-nodes.svg"
// import twitter from "@fortawesome/fontawesome-free/svgs/brands/twitter.svg"
import "moment/locale/de"
import "twin.macro"
import { Link } from "gatsby"
import Calendar from "../../images/svgs/calendar.svg"
import Icon from "../icon"
import moment from "moment/moment"
import parse from "html-react-parser"
import React from "react"
import ReactHtmlParser from 'react-html-parser';

const EventTeaser = ({
  id,
  slug,
  title,
  date,
  content,
  excerpt,
  startDate,
  endDate,
  uri,
  venue,
  protagonists,
  eventsCategories: { nodes: categories },
}) => {
  return (
    <Link to={uri} tw="">
      <div tw="grid grid-cols-12 gap-5">
          <div tw="col-span-12 sm:col-span-4 lg:col-span-4 xl:col-span-3">
            {startDate && (
              <>
                <div tw="text-4xl font-bold lg:text-5xl">
                  {moment(startDate).format("HH:mm")} Uhr
                  {/* - {moment(endDate).format("HH:mm")} Uhr */}
                </div>
                {/**<div tw="flex items-start">
                  <Icon svg={Calendar} tw="mr-4 mt-1 h-5 w-5 fill-current" />
                  <div tw="text-xl">
                    {moment(startDate).format("HH:mm")}-{moment(endDate).format("HH:mm")} Uhr
                    {moment(startDate).format("LL")}
                  </div>
                </div>**/}
              </>
            )}
          </div>

          <div tw="col-span-12 sm:col-span-8 xl:col-span-5">
            {categories && categories.length > 0 && (
              <div tw="inline-block text-lg font-bold">
                {categories.map(({ name }, index) => (
                  <span key={`category-${id}-${index}`} tw="mr-4">
                    {name}
                  </span>
                ))}
              </div>
            )}
            <div tw="text-xl font-bold md:text-3xl">
              {content ? <Link to={uri}>{title}</Link> : <div>{title}</div>}
            </div>
          </div>

          <div tw="col-span-12 col-start-1 sm:col-span-8 sm:col-start-5 xl:col-span-4">
            {excerpt || content ? (
              <div className="wp-content" tw="mb-4">
                {parse(excerpt || content)}
              </div>
            ) : null}

            {content && (
              <Link to={uri} tw="mb-8 block text-sm font-bold text-link">
                Mehr Details
              </Link>
            )}
            <div tw="grid grid-cols-2 gap-8">
              {protagonists &&
                protagonists?.moderation &&
                protagonists?.moderation.length > 0 && (
                  <div>
                    <div tw="text-sm font-bold">Moderation</div>

                    {protagonists &&
                      protagonists?.moderation &&
                      protagonists?.moderation.map((protagonist, index) => (
                        <div key={`moderation-${id}-${index}`} tw="text-sm">
                          {ReactHtmlParser(protagonist?.member.title)} {ReactHtmlParser(protagonist?.member.firstname)} {ReactHtmlParser(protagonist?.member.lastname)}
                        </div>
                      ))}
                  </div>
                )}
              {protagonists &&
                protagonists?.together &&
                protagonists?.together.length > 0 && (
                  <div>
                    <div tw="text-sm font-bold">Mit</div>
                    {protagonists &&
                      protagonists?.together &&
                      protagonists?.together.map((protagonist, index) => (
                        <div key={`together-${id}-${index}`} tw="text-sm">
                          {ReactHtmlParser(protagonist?.member.title)} {ReactHtmlParser(protagonist?.member.firstname)} {ReactHtmlParser(protagonist?.member.lastname)}
                        </div>
                      ))}
                  </div>
                )}
            </div>
          </div>
      </div>
    </Link>
  )
}

EventTeaser.propTypes = {}

export default EventTeaser
